import { defineStore } from 'pinia'
import {ResultasticFeature} from "@spoferan/spoferan-ts-core";

export const useResultasticStore = defineStore('resultastic', {
    state: () => ({
        showBookingModal: false,

        features: []
    }),
    actions: {
        async loadFeatures() {
            if (this.features.length > 0) {
                return;
            }

            const {$web, $apiFetch} = useNuxtApp();
            const {data} = await $apiFetch('/v1/resultastic/features', {
                guest: true
            });

            // Enrich the features with links to their detail info pages
            this.features = data.map(feature => {
                switch (feature.value) {
                    case ResultasticFeature.Timekeeping:
                        feature.details = $web(`resultastic.features.bibkeeper`);
                        break;
                    case ResultasticFeature.ParticipantRegistration:
                        feature.details = $web(`resultastic.features.participant_management`);
                        break;
                    case ResultasticFeature.VirtualEvents1:
                    case ResultasticFeature.VirtualEvents2:
                        feature.details = $web(`spoferan.virtual_events`);
                        break;
                    case ResultasticFeature.BasicMemberManagement:
                    case ResultasticFeature.PremiumMemberManagement:
                        feature.details = $web(`resultastic.features.team_management`);
                        break;
                }

                return feature;
            });
        }
    },
})